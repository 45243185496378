<template>
  <v-row>
    <styling @info="setInfo"/>
    <v-col cols="12" lg="5" xl="6" class="d-flex align-center">
      <v-container>
        <div class="pa-7 pa-sm-12">
          <v-row v-if="!submitted">
            <v-col cols="12" lg="9" xl="9">
              <h2 class="font-weight-bold mt-4 blue-grey--text text--darken-2 mb-5">
                {{ $store.getters.translate("registration") }}
              </h2>
              <v-stepper open-all flat v-model="stepper" vertical>
                <v-stepper-step editable :complete="stepper > 1" step="1">
                  {{ $store.getters.translate("company") }}
                </v-stepper-step>
                <v-stepper-content step="1">
                  <v-card flat>
                    <v-form ref="form_company" v-model="valid_company">
                      <v-combobox
                        class="mt-5"
                        :label="$store.getters.translate('name')"
                        v-model="record.company.name"
                        required
                        v-debounce:230ms.lock="getCompanies"
                        :multiple="false"
                        auto-select-first
                        :items="companies"
                        item-value="name"
                        item-text="name"
                        @change="setCompany"
                        clearable/>
                      <v-text-field
                        :disabled="company_locked"
                        :label="$store.getters.translate('phonenumber')"
                        v-model="record.company.phonenumber"
                        placeholder="+31612345678"
                        required/>
                      <base-addresses
                          address_type="visiting"
                          label="address"
                          mapID="map"
                          :disabled="company_locked"
                          required
                          v-model="record.company.addresses"
                          @change="one_address_required = false"
                          :only_one="true"/>
                      <small style="position: relative; top: -20px" v-if="one_address_required" class="red--text">
                        {{ $store.getters.translate("one_address_required") }}
                      </small>
                      <v-text-field
                        :disabled="company_locked"
                        :label="$store.getters.translate('email')"
                        v-model="record.company.email"/>
                      <v-btn class="mt-5" @click="nextStep('contact')" block color="primary">
                        {{ $store.getters.translate("next") }}
                        <v-icon right>mdi-arrow-right</v-icon>
                      </v-btn>
                    </v-form>
                  </v-card>
                </v-stepper-content>
                <v-stepper-step editable :complete="stepper > 2" step="2">
                  {{ $store.getters.translate("contact") }}
                </v-stepper-step>
                <v-stepper-content step="2">
                  <v-card flat>
                    <v-form ref="form_contact" v-model="valid_contact">
                      <v-switch
                        :label="$store.getters.translate('no_contact')"
                        v-model="no_contact"/>
                      <v-text-field
                          v-if="!record.contact.no_contact"
                          :label="$store.getters.translate('first_name')"
                          v-model="record.contact.person.first_name"
                          required
                          validate-on-blur/>
                      <v-text-field
                          v-if="!record.contact.no_contact"
                          :label="$store.getters.translate('insertion')"
                          v-model="record.contact.person.insertion"/>
                      <v-text-field
                          v-if="!record.contact.no_contact"
                          :label="$store.getters.translate('last_name')"
                          v-model="record.contact.person.last_name"
                          required
                          validate-on-blur/>
                      <v-text-field
                          v-if="!record.contact.no_contact"
                          :label="$store.getters.translate('mobilenumber')"
                          v-model="record.contact.person.phonenumber"
                          placeholder="+31612345678"
                          validate-on-blur/>
                      <v-text-field
                          v-if="!record.contact.no_contact"
                          :label="$store.getters.translate('email')"
                          v-model="record.contact.person.email"/>
                      <v-btn class="mt-5" @click="nextStep('worker')" block color="primary">
                        {{ $store.getters.translate("next") }}
                        <v-icon right>mdi-arrow-right</v-icon>
                      </v-btn>
                    </v-form>
                  </v-card>
                </v-stepper-content>
                <v-stepper-step :editable="valid_contact" :complete="stepper > 3" step="3">
                  {{ $store.getters.translate("worker") }}
                </v-stepper-step>
                <v-stepper-content step="3">
                  <v-card flat>
                    <v-form ref="form_worker" v-model="valid_worker">
                      <template v-for="field in usual_fields">
                        <template v-if="field.name === 'name'">
                          <v-text-field
                              :key="field.name + '_first_name'"
                              :label="$store.getters.translate('first_name')"
                              v-model="record.worker.person.first_name"
                              required
                              :hint="$store.getters.translate('required')"/>
                          <v-text-field
                              :key="field.name + '_insertion'"
                              :label="$store.getters.translate('insertion')"
                              v-model="record.worker.person.insertion"/>
                          <v-text-field
                              :key="field.name + '_last_name'"
                              :label="$store.getters.translate('last_name')"
                              v-model="record.worker.person.last_name"
                              required
                              :hint="$store.getters.translate('required')"/>
                          <v-text-field
                              :key="field.name + '_initials'"
                              :label="$store.getters.translate('initials')"
                              v-model="record.worker.person.initials"/>
                        </template>
                        <template v-else-if="field.name === 'reference'">
                          <base-field
                              :key="field.name"
                              :value="record.worker[field.name]"
                              :field="field"
                              @change="usualFieldDataChanged"/>
                        </template>
                        <template v-else>
                          <base-field
                              :key="field.name"
                              :value="record.worker.person[field.name]"
                              :field="field"
                              @change="usualFieldDataChanged"/>
                        </template>
                      </template>
                      <v-text-field
                          :label="$store.getters.translate('email')"
                          v-model="record.worker.person.email"/>
                      <v-checkbox
                          :label="$store.getters.translate('access_to_worker_portal')"
                          v-model="record.worker.person.access_to_portal"
                          persistent-hint/>
                      <v-btn :loading="loading" class="mt-5" color="primary" @click="save" :disabled="canSubmit" block>
                        {{ $store.getters.translate("submit_registration") }}
                      </v-btn>
                    </v-form>
                  </v-card>
                </v-stepper-content>
              </v-stepper>
            </v-col>
          </v-row>
          <v-row v-if="submitted">
            <v-col cols="12" lg="9" xl="9">
              <v-alert class="pa-12" color="success" outlined>
                <v-icon color="success" left>mdi-checkbox-marked-circle-outline</v-icon>
                {{ $store.getters.translate("your_registration_was_successful") }}
              </v-alert>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import Styling from "../authentication/includable/Styling";
import lodash from "lodash";
import helpFunctions from "../../plugins/helpFunctions";
import BaseAddresses from "../../components/commonComponents/BaseAddresses";
export default {
  name: "FullLogin",
  title: process.env.VUE_APP_TITLE ? process.env.VUE_APP_TITLE : "Voltado",
  components: {
    BaseAddresses,
    Styling,
  },
  data: () => ({
    valid_worker: false,
    valid_company: false,
    valid_contact: false,
    info: {},
    valid: false,
    link: "",
    loading: false,
    record: {},
    stepper: 1,
    submitted: false,
    no_contact: false,
    one_address_required: false,
    usual_fields: [],
    custom_fields: [],
    countries: [],
    nationalities: [],
    countries_loaded: false,
    nationalities_loaded: false,
    companies: [],
    company_locked: false,
  }),
  created() {
    document.title = process.env.VUE_APP_TITLE;
    this.record = helpFunctions.register_worker_model;
    this.link = this.$route.query.link;
    this.getCountries();
    this.getNationalities();
    this.$store.dispatch("refreshTranslations");
  },
  methods: {
    nextStep(value) {
      if (value === "company") {
        this.stepper = 1;
      }
      if (value === "contact") {
        this.$refs.form_company.validate();
        if (this.record.company.addresses.length == 0) {
          this.one_address_required = true;
        }
        if (this.record.company.addresses.length > 0) {
          this.one_address_required = false;
        }
        if (this.valid_company == true && this.one_address_required == false) {
          return (this.stepper = 2);
        }
        return;
      }
      if (value === "worker") {
        this.$refs.form_contact.validate();
        if (this.record.contact.no_contact == true) {
          return (this.stepper = 3);
        }
        if (this.valid_contact == true) {
          return (this.stepper = 3);
        }
        return;
      }
    },
    setInfo(info) {
      this.info = info;
    },
    save() {
      if(!this.loading) {
        this.loading = true;
        this.$http
            .post(this.$store.getters.appUrl + "v2/registration", this.record)
            .then((response) => {
              this.loading = false;
              if (response.status == 200) {
                this.submitted = true;
                this.reset();
              }
              else {
                this.$toasted.error(response.data.message);
              }
            })
            .catch(() => {
              this.loading = false;
              this.$toasted.error(this.$store.getters.translate("please_check_the_form_for_errors"));
            });
      }
    },
    getCompanies: lodash.debounce(function (term) {
      if (term.length >= 3) {
        this.$http
          .get(this.$store.getters.appUrl + "v2/registration/companies?name=" + term)
          .then((response) => {
            this.companies = response.data;
          })
          .catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toasted.error(error);
            }
          });
      } else {
        this.companies = [];
      }
    }, 100),
    setCompany(company) {
      if (company && company.id) {
        this.company_locked = true;
        this.record.company.name = company.name;
        this.record.company.phonenumber = company.phonenumber;
        this.record.company.addresses = company.addresses;
        this.record.company.email = company.emailaddresses.length > 0 ? company.emailaddresses[0].emailaddress : "";
        this.one_address_required = false;
      }
      if (company.length < 3 || !company) {
        this.company_locked = false;
        this.record.company.name = "";
        this.record.company.phonenumber = "";
        this.record.company.addresses = [];
        this.record.company.email = "";
        this.one_address_required = true;
      }
    },
    usualFieldDataChanged(data) {
      if(data[0] === 'reference') {
        this.record.worker[data[0]] = data[1];
      }
      else {
        this.record.worker.person[data[0]] = data[1];
      }
    },
    customFieldDataChanged(data) {
      this.record.worker.custom_fields[data[0]] = data[1];
    },
    async getFields() {
      this.$http
          .get(this.$store.getters.appUrl + "v2/registration/fields?source=site&show_on_creation=true&model_type=worker")
          .then((response) => {
            this.custom_fields = [];
            this.usual_fields = [];
            response.data.data.forEach((field) => {
              if (field.custom_field == 1) {
                this.custom_fields.push(field);
              }
              else if(field.name !== 'tags') {
                if(field.name === 'country_of_birth_id') {
                  field.available_options = this.countries;
                }
                else if(field.name === 'nationality_id') {
                  field.available_options = this.nationalities;
                }
                else if(field.field_type === 'select_single' || field.field_type === 'select_multiple') {
                  for (let i = 0; i < field.available_options.length; i++) {
                    field.available_options[i] = {
                      text: this.$store.getters.translate(field.available_options[i]),
                      value: field.available_options[i],
                    };
                  }
                }
                this.usual_fields.push(field);
              }
            });
            this.loading = false;
          })
          .catch((error) => {
            this.$toasted.error(error);
            this.loading = false;
          });
    },
    getCountries() {
      this.$http
          .get(this.$store.getters.appUrl + "v2/registration/countries")
          .then((response) => {
            this.countries = [];
            response.data.forEach((option) => {
              this.countries.push({
                value: option.country_id,
                text: option.country_name,
              });
            });
            this.countries_loaded = true;
            if(this.nationalities_loaded) {
              this.getFields();
            }
          })
          .catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toasted.error(error);
            }
          });
    },
    getNationalities() {
      this.$http
          .get(this.$store.getters.appUrl + "v2/registration/nationalities")
          .then((response) => {
            this.nationalities = [];
            response.data.forEach((option) => {
              this.nationalities.push({
                value: option.nationality_id,
                text: option.nationality_name,
              });
            });
            this.nationalities_loaded = true;
            if(this.countries_loaded) {
              this.getFields();
            }
          })
          .catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toasted.error(error);
            }
          });
    },
    reset() {
      this.record = {
        worker: {
          notify: false,
          reference: "",
          custom_fields: {},
          person: {
            type: "worker",
            initials: null,
            first_name: null,
            insertion: null,
            last_name: null,
            date_of_birth: null,
            phonenumber: null,
            mobilenumber: null,
            billingnumber: null,
            gender: null,
            place_of_birth: null,
            addresses: [],
            access_to_portal: true,
            country_of_birth_id: null,
            nationality_id: null,
            financial_name: null,
          },
        },
        company: {
          name: null,
          reference: null,
          phonenumber: null,
          billingnumber: null,
          addresses: [],
        },
        contact: {
          notify: false,
          person: {
            type: "contact",
            initials: null,
            first_name: null,
            insertion: null,
            last_name: null,
            date_of_birth: null,
            phonenumber: null,
            billingnumber: null,
            gender: null,
            country_of_birth_id: null,
            place_of_birth: null,
            nationality_id: null,
            addresses: [],
          },
          no_contact: false,
        },
      };
    },
  },
  computed: {
    canSubmit() {
      if (!this.submitted && this.valid_contact && this.valid_worker && this.valid_company) {
        return false;
      }
      return true;
    },
  },
  watch: {
    no_contact: {
      handler() {
        this.record.contact.no_contact = this.no_contact;
        this.valid_contact = true;
      },
    },
    record: {
      handler() {
        if (this.record && this.record.company && this.record.company.addresses && this.record.company.addresses.length > 0) {
          this.one_address_required = false;
        } else {
          this.one_address_required = true;
        }
      },
    },
  },
};
</script>
